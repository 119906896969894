/* eslint-disable react/no-danger */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Layout, PostSummary, Pagination } from '../../components';
import categoryStyles from './category.module.scss';

const Category = ({ pageContext }) => {
    const {
        prevPageLink, nextPageLink, currentPage, metaTitle, metaDescription, posts,
    } = pageContext;

    return (
        <Layout
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            metaPageNumber={currentPage}
        >
            <div className={categoryStyles.main}>
                {_.map(posts, (post) => {
                    return <PostSummary key={post.node.path} node={post.node} />;
                })}
                <Pagination prevPageLink={prevPageLink} nextPageLink={nextPageLink} />
            </div>
        </Layout>
    );
};

Category.propTypes = {
    pageContext: PropTypes.shape({
        prevPageLink: PropTypes.string,
        nextPageLink: PropTypes.string,
        currentPage: PropTypes.number,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        posts: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default Category;
